$button_arrow_border_width2: 2;
@if $button_arrow_border_width == 1 {
    $button_arrow_border_width2: 2;
  } @else {
    $button_arrow_border_width2: 3;
  }

.exNav.exNav--typeA ul li a,
.arrow,
.megaMenu .button,
.megaMenu .megaMenu__button,
.itemList--typeC .itemList__unit,
.articleList--typeB .articleList__text,
.articleList--typeD .articleList__wrap,
[class*="iconButton"],
a[class*="iconButton"],
.cateList .button,
.side__cateNav ul li a,
.drawer__nav li a,
.side__txtBnr,
[class*='fNav--type']:not(.fNav--typeA) .fNav__list a,
.gNav.gNav--typeD > ul > li.hasChildren > a > span:last-child,
.hdrCatMenu ul a,
.arrow--typeB,
.keywordRanking__toggle li a,
a.megaMenu__arrow,
li.hasChildren > a{
  position: relative;
  transition: 0.3s;

  &:hover{
    transition: 0.3s;
  }

  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 6px;
    height: 6px;
    border-top: $button_arrow_border_width+px solid $color_text;
    border-right: $button_arrow_border_width+px solid $color_text;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: top center;

    transition: 0.15s;
  }

  &:hover{
    &::after{
      transition: 0.2s;
      margin-left: 3px;
      border-color: $color_primary;
    }
  }
}

.megaMenu .button,
.megaMenu .megaMenu__button,
.side__cateNav ul li a,
.hdrCatMenu ul a,
.drawer__nav li a,
.keywordRanking__toggle li a,
[class*='fNav--type']:not(.fNav--typeA) .fNav__list a,
[class*="iconButton"],
a[class*="iconButton"],
.cateList .button,
.side__txtBnr,
.arrow{
  position: relative;
  padding-right: 15px;
  &:after{
    right: 15px;
  }
  &:hover{
    &:after{
      right: 12px;
    }
  }
}
[class*='fNav--type']:not(.fNav--typeA) .fNav__list a{
  padding-right: 0;
}

li.hasChildren{
   > a{
     &::after{
       transform: rotate(135deg) translateY(-50%);
     }
   }
  &:hover> a::after,
  > a.open::after{
      transform: rotate(-45deg) translateY(-50%);
      border-color: $color_primary;
  }
}

.gNav.gNav--typeD > ul > li.hasChildren{
  > a > span{
   &::after{
     transform: rotate(135deg) translateY(-50%) !important;
   }
 }
  &:hover > a > span::after,
   > a > span.open::after{
      transform: rotate(-45deg) translateY(-50%) !important;
      border-color: $color_primary;
  }
}

.arrow--none{
  &:after{
    content: none !important;
  }
}

.arrow--typeB,
a.arrow--typeB{
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  &:after{
    content: "";
    width: 30px;
    height: 8px;
    right: 15px;
    border: none;
    border-bottom: $button_arrow_border_width+px solid $color_primary_text;
    border-right: $button_arrow_border_width2+px solid $color_primary_text;
    transform: skew(45deg) translateY(-6px);
  }
  &:hover{
    &:after{
      right: 10px;
      border-color: $color_primary_text;
    }
  }
  &.button--typeB{
    &:after{
      border-color: $color_primary;
    }
  }
}
