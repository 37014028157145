.gNav.gNav--typeB{
  > ul{
    > li{
      margin-right: 30px;
      &:last-child{
        margin-right: 0;
      }
      a{
        font-size: 12px;
        display: block;
        strong{
          font-size: 14px;
        }

        @media all and (-ms-high-contrast:none){
          line-height: 1.4;
          >*{
            flex-basis: auto;
          }
        }

      }
    }
  }

}
