table{
  tr{
    th,td{
      overflow-wrap : break-word;
      line-height: 1.6;
    }
  }
}
table.table--basic{
  width: 100%;
  table-layout: fixed;
  tr{
    th,td{
      border: 1px solid $color_border;
      padding: 0.5em;
    }
    th{
      background: lighten($color_primary,65);
      font-weight: normal;
      width: 25%;
    }
  }
  &.table--gray{
    tr{
      th{
        background: #f2f2f2;
      }
    }
  }
}

table.table--menu{
  width: 100%;
  caption{
    border-bottom: 1px solid lighten($color_text,50);
    padding: 0.5em 0.5em 0.75em;
    font-weight: bold;
    font-size: $base_font_size+0.2+rem;
    @media (max-width: 767px) {
      font-size: $base_font_size+rem;
    }
  }
  tr{
    th,td{
      border-bottom: 1px solid $color_border;
      padding: 0.75em 0.5em;
      word-break: break-all;
      // &:last-child{
      //   text-align: right;
      //   min-width: 5em;
      //   vertical-align: middle;
      // }
      small{
        color: lighten($color_text,30);
      }
    }
    th{
      font-weight: normal;
      text-align: left;
    }
  }
}

table.table--schedule{
  width: 100%;
  tbody,thead{
    tr{
      th,td{
        padding: 0.5em;
        border: 1px solid darken($color_primary,5);
        text-align: center;
      }
    }
  }
  thead{
    tr{
      th,td{
        background: lighten($color_primary,5);
        font-weight: normal;
      }
    }
  }
  tbody{
    tr{
      th,td{
        font-weight: normal;
        color: $color_text;
        &:first-child{
          width: 30%;
          min-width: 10rem;
        }
      }
      th{
        width: 25%;
      }
    }
  }
}
