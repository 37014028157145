/* 検索ワードリスト */
.keywordSearch {
  .row {
    align-items: center;
    justify-content: center;
    @media (min-width: $braekpoint_sp1 +px){
      flex-wrap: nowrap;
    }
  }
  .button {
    border-radius: 0;
    width: 100%;
    max-width: 345px;
    margin: 30px 0;
    @media (max-width: $braekpoint_sp1 - 1+px){
      margin: 15px 0;
    }
  }
}

.ks__searchForm {
  padding-right: 20px;
  margin-bottom: 0;
  @media (max-width: $braekpoint_sp1 - 1+px){
    padding-right: $sp_base_grid_margin+px;
  }
}
.ks__keywords {
  margin-bottom: 0;
  @media (max-width: $braekpoint_sp1 - 1+px){
    border: 0;
    padding-top: $sp_bezel+px;
  }
}
.keywordList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  @media (max-width: $braekpoint_sp1 - 1+px){
    margin: 0 -5px;
    justify-content: center;
  }
  li{
    margin: 5px;
    @media (max-width: $braekpoint_sp1 - 1+px){
      margin: 0 2.5px 5px;
    }
    a {
      display: block;
      background: $color_highlight_bg;
      border: 1px solid $color_highlight_bg;
      font-size: 12px;
      line-height: 16px;
      padding: 3px 0.5em;
      text-align: center;
      border-radius: 6px;
      &:hover {
        background: transparent;
      }
      @media (max-width: $braekpoint_sp2 - 1+px){
        font-size: 10px;
      }
    }
  }
}

.keywordSearch--typeA {
  .ks__searchForm {
    @media (min-width: $braekpoint_sp1 +px){
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .ks__keywords {
    @media (min-width: $braekpoint_sp1 +px){
      flex: 0 0 auto;
      max-width: 75%;
      width: auto;
    }
  }
}

.keywordSearch--typeB{
  .ks__searchForm{
    width: 600px;
    max-width: 100%;
    margin: 0 auto 30px;
  }
  .ks__keywords{
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    h3{
      flex: 0 0 80px;
      font-weight: $strong_font_weight;
    }
    @media (max-width: $braekpoint_sp1 - 1+px){
      h3{
        font-size: 12px;
      }
      ul{
        justify-content: flex-start;
      }
    }
  }
  .keywordList{
    justify-content: center;
  }
}
