html{
  font-size:10px; //変更しないでください
  letter-spacing: 0.5px;
  scroll-padding-top: 120px;
  @media (max-width: 767px) {
    scroll-padding-top: 80px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

small{
  font-size: 12px;
}
body{
  font-family: $base_font_family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "palt";
  font-size: $base_font_size+px;
  color: $color_text;
  min-width: inherit;
  letter-spacing: 0.05em;
  background: $color_body_bg;
  font-weight: $base_font_weight;

  @if $device_mode == 'pc'{
    min-width: $container+px;
  }
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: auto;
}

input,textarea,select,button,pre{
  font-family: $base_font_family;
  color: $color_text;
}
strong{
  font-weight: $strong_font_weight;
}
figure{
  text-align: center;
  margin: 0;
  img{
    max-width: 100%;
  }
}

main{
  display: block;
  width: 100%;
  position: relative;
}

section{
  display: block;
  width: 100%;
  margin-bottom: $pc_section_margin_bottom+px;
  @if $device_mode != 'pc'{
    @media (max-width: 767px) {
      margin-bottom: $sp_section_margin_bottom+px;
    }
  }
}
p{
  display: block;
  width: 100%;
  margin-bottom: 24px;
  font-feature-settings: "palt";
  font-size: $base_font_size+px;
  line-height: $base_font_size / 14 * 24 +px;
  &:last-child{
    margin-bottom: 0;
  }
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_sp1 - 1+px) {
      font-size: $base_font_size_sp+px;
    }
  }
}

hr{
  border-color: $color_border;
}

a{
  color: $color_text_link;
  text-decoration: none;
  &:visited{
    color: $color_text_link;
    text-decoration: none;
  }
  &:hover,&:focus{
    text-decoration: none;
  }
}
a[href^="tel:"] {
  color: $color_text_link;
  text-decoration: none;
}



@if $device_mode != 'pc'{
  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 $sp_bezel+px;
    max-width: 100%;
  }
  @media (min-width: $container+px) {
    .container {
      padding: 0;
      max-width: $container+px;
    }
  }
}@else{
  .container {
    width: 100%;
    max-width: $container+px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: $container - 40 +px) {
      padding: 0 20px;
    }
  }
}

@if $device_mode == 'pc'{
  .container,.hdrInner{
    &.pcFull{
      max-width: 100%;
      padding: 0 30px;
    }
  }
}

@if $device_mode == 'responsive'{
  @media (min-width: $container+px) {
    .container,.hdrInner{
      &.pcFull{
        max-width: 100%;
        padding: 0 30px;
      }
    }
  }
}

.noHiddenContainer{
  overflow: hidden;
  .container,.slick-list{
    overflow: visible;
  }
}

.container--narrow{
  width: 800px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}


.row img{
  max-width: 100%;
}

i.material-icons{
  font-size: 24px;
}


@if $device_mode == 'pc'{
  #hdr, #ftr{
    min-width: 1200px;
  }
}


@if $device_mode == 'pc'{
  .spOnly{
    display: none !important;
  }
}

@if $device_mode == 'sp'{
  .pcOnly{
    display: none !important;
  }
}

@if $device_mode == 'responsive'{
  @media (max-width: $braekpoint_tab - 1+px) {
    .pcOnly{
        display: none !important;
      }
  }
  @media (min-width: $braekpoint_tab+px) {
    .spOnly{
      display: none !important;
    }
  } 
  #container:not(.topPage) {
    max-width:$container+px;
    margin: auto;

  }
  @media (max-width: $container+px) and (min-width: $braekpoint_tab+px) {
    #container:not(.topPage) {
      padding: 0 20px;
    }
    @if $top_columns == 1{
      @if $under_columns == 1{
        #container #side { 
          display: none !important;
        }
      }@else{
        #container.topPage #side {
          display: none !important;
        }
        #container:not(.topPage) #main {
          width: calc(100% - #{$side_column_width + 20}px) !important;
        }
      }
    }@else{
      #container #main {
        width: calc(100% - #{$side_column_width + 20}px) !important;
      }

    }
  }
  @media (max-width: $braekpoint_tab - 1+px){
    #container #main {
      width: 100% !important;
    }
    #container #side {
      display: none !important;
    }
  }
}

@if $device_mode != 'pc'{
  @media (max-width: $container+px) {
    #container{
      max-width: 100% !important;
    }
  }
}

@if $device_mode != 'sp'{
  @if $top_columns == 1{
    @if $under_columns == 1{
      /*サイド メイン100%*/
      #side{
        display: none;
      }
      #main{
        float: none;
      }
      #container:not(.topPage){
        width: $container+px;
        margin-left: auto;
        margin-right: auto;
      }
      #container.topPage #main {
        width: 100%;
        padding: 0;
      }
    }@else{
      #container.topPage #side {
        display: none;
      }
      #container:not(.topPage){
        width: $container+px;
        margin-left: auto;
        margin-right: auto;
      }
      /*サイド*/
      #container:not(.topPage) #side {
        float: left;
        width: $side_column_width+px;
        @media (max-width: $container - 40 +px) {
          width: $side_column_width - 40 +px;
        }
      }
      /*メイン*/
      #container:not(.topPage) #main {
        float: right;
        width: $main_column_width+px;
      }
    }

  }@else{
    #container{
      width: $container+px;
      margin-left: auto;
      margin-right: auto;
    }
    /*サイド*/
    #container #side {
      float: left;
      width: $side_column_width+px;
      @media (max-width: $container - 40 +px) {
        width: $side_column_width - 40 +px;
      }
    }
    /*メイン*/
    #container #main {
      float: right;
      width: $main_column_width+px;
    }
  }
}


#container:after{
  content: "";
  display: table;
  clear: both;
}
