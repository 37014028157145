.newsList,.newsList li{list-style: none;}
.newsList a:hover,a.newsBnr:hover{
  color: $color_primary;
  text-decoration: none;
  img{
    opacity: 0.8;
  }
}
.newsBnr{
  text-align: center;
  display: block;
  img{
    max-width: 100%;
  }
}
.newsList{
  max-width: 100%;
  li{
    list-style: none;
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid $color_border;
    time{
      font-size: 12px;
      line-height: 24px;
      flex: 0 0 fit-content;
      padding-right: 1.5em;
    }
    h3{
      line-height: 24px;
      font-size: 14px;
      font-weight: $strong_font_weight;
      flex: 1 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    @media (max-width: $container+px) {
      // display: block;
      flex-wrap: wrap;
      time{
        font-size: 12px;
        // max-width: 100%;
        // flex: 0 0 100%;
        margin-bottom: 5px;
        line-height: 1;
      }
      span{
        margin-bottom: 5px;
        order: 0;
      }
      h3{
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.newsList--typeB{
  display: flex;
  border-top: 1px solid $color_border;
  border-bottom: 1px solid $color_border;
  padding: 10px 0;
  .newsList__head{
    margin: 0 40px;
    flex: 0 1 auto;
  }
  .newsList__body{
    flex: 1 1 auto;
    padding: 10px 0;
    ul,.makeNewsWrap{
      padding: 0 10px 0 0;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: scroll;
      height: 150px;
      li{
        border-bottom: 0;
      }
    }
  }
  .newsList__head + .newsList__body {
   max-width: calc(100% - 190px);
  }
  &.newsList--typeB2 {
   .newsList__body {
    max-width: 720px;
    margin: 0 auto;
   }
 }

  @if $device_mode != "pc"{
    @media (max-width: $braekpoint_sp1 - 1+px) {
      flex-wrap: wrap;
      border: none;
      .newsList__head{
        margin: 0;
        flex: 0 1 100%;
        margin-bottom: 20px;
      }
      .newsList__body{
        border-top: 1px solid $color_border;
        border-bottom: 1px solid $color_border;
        flex: 1 1 100%;
        padding: 10px 0;
        ul{
          padding: 10px 0;
          overflow: scroll;
          overflow-x: hidden;
          overflow-y: scroll;
          height: 150px;
          li{
            border-bottom: 0;
            &:first-child {
             padding-top: 0;
            }
          }
        }
      }
      .newsList__head + .newsList__body {
       max-width: 100%;
      }
      &.newsList--typeB2 {
       .newsList__body {
        max-width: 100%;
       }
      }
    }
  }
}

.highlight--narrow{
  padding: 20px 0;
}

.newsList--typeC{
  display: flex;
  .newsList__head {
    display: flex;
    align-items: center;
    margin-right: 20px;
    max-width: 100px;
    vertical-align: middle;
    flex: 0 0 100px;
    h2{
      font-weight: $base_font_weight;
      text-align: center;
      font-size: 12px;
      line-height: 26px;
      background: $color_primary;
      color: $color_primary_text;
      width: 100%;
    }
    i{
      font-size: 14px;
      vertical-align: middle;
      line-height: 27px;
      display: inline-block;
    }


  }
  .newsList__body {
    max-width: calc(100% - 120px);
    flex: calc(100% - 120px);
    // ul li:not(:first-child){
    //   display: none;
    // }
    ul li{
      border-bottom: 0;
      line-height: 30px;
      padding: 3px 0;
    }
    // ↓スクロールさせる場合
    // ul{
    //   padding: 0px;
    //   overflow: scroll;
    //   overflow-x: hidden;
    //   overflow-y: scroll;
    //   height: 30px;
    //   li{
    //     padding: 0;
    //     line-height: 30px;
    //     margin-bottom: 5px;
    //   }
    // }
  }
  @media (max-width: $braekpoint_sp1 - 1+px){
    .newsList__head {
      margin-right: 15px;
      max-width: 70px;
      vertical-align: middle;
      flex: 0 0 70px;
      h2{
        i{display: none;}
      }
    }
    .newsList__body {
      max-width: calc(100% - 85px);
      flex: calc(100% - 85px);
      h3{
        font-size: 12px;
      }
    }
  }
}

.newsList__cate{
  margin-right: 20px;
  a{
    color:#fff;
    background: #666;
    min-width: 6em;
    display: inline-block;
    text-align: center;
    font-weight: $strong_font_weight;
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 11px;
    @media (max-width: $container+px) {
      font-size: 10px;
      padding: 4px 10px;
    }
    &:hover{
      opacity: 0.8;
      color:#fff;
    }
  }
  &.nc__new_products a{
    background: #f9b34a;
  }
  &.nc__news a{
    background: #2da9c5;
  }
  &.nc__media a{
    background: #cd49a8;
  }
  &.nc__columns a{
    background: #4ca864;
  }
  &.nc__recipe a{
    background: #df3838;
  }
}
