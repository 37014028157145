@charset "utf-8";

// ver1.2.1

$device_mode: 'responsive'; // pc,sp,responsive

// フォント
  // ベースフォント
  $base_font_family: 'Noto Sans JP', '游ゴシック', YuGothic, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  // $base_font_family: 'Noto Serif JP', "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  // $base_font_family: 'Lato', 'Noto Sans JP', '游ゴシック', YuGothic, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

  // 見出しフォント
  // $heading_font_family: 'Noto Sans JP', '游ゴシック', YuGothic, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  // $heading_font_family: 'Noto Serif JP', "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  $heading_font_family: 'Roboto', 'Noto Sans JP', '游ゴシック', YuGothic, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

  // WEBフォントインポート
  // @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap');
  // @import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,500,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;700&display=swap');
  // フォントサイズの設定
  $head_font_size: 40; //見出しフォントサイズ ※単位：px ex) 14,12
  $head_font_size_sp: 30; //見出しフォントサイズ ※単位：px ex) 14,12
  $base_font_size: 17; //ベースフォントサイズ ※単位：px ex) 14,12
  $base_font_size_sp: 12; //ベースフォントサイズ ※単位：px ex) 14,12

  // フォントの太さの設定
  $head_font_weight: 700; //見出し太さ ex) 500,700
  $strong_font_weight: 700; //ボタンなど太さ ex) 500,700
  $base_font_weight: 400; //ベース太さ ex) 500,700


// 幅・余白の設定
$container: 1200; //コンテナ幅 ex) 1200,1000

$base_grid_margin: 15; //グリッドシステムの余白 30pxの場合は15と記述（PC）
$sp_base_grid_margin: 7.5; //グリッドシステムの余白 14pxの場合は7と記述(SP)

$pc_section_margin_bottom: 60; //セクションの下余白(PC)
$sp_section_margin_bottom: 40; //セクションの下余白(SP)

$pc_h2_margin_bottom: 40; //H2の下余白(PC)
$sp_h2_margin_bottom: 20; //H2の下余白(SP)

$sp_bezel: 15; //SPの画面左右余白


// ブレイクポイントの設定
$braekpoint_tab: 992;
$braekpoint_sp1: 768;
$braekpoint_sp2: 480;

$header_fixed: 'true'; // ヘッダー固定

$logo_width: 186; //SPロゴの横幅 ※単位：px ex) 150


// カラム数 
$top_columns:  1; //TOPページのカラム数　1,2
$under_columns: 1; //下層ページのカラム数　1,2

// カラムサイズ
$side_column_width: 250; //サイドカラムの横幅
$main_column_width: 910; //メインカラムの横幅


// 基本ボタンタイプ設定
$button_type: 'B'; //A,B,C
$button_radius: 100; //丸ボタンの場合は「100」
$button_arrow_border_width: 2; //ボタン矢印の線の太さ


// リセット・フレームワーク
@import "_reset";
@import "_grid";

//色定義
@import "_color";

// ベース設定
@import "_base";
@import "_utility";
@import "_side";

// CMS設定
// @import "cms/_creatorMode"; //Makeshop クリエイターモード
@import "cms/_colormeResponsive"; //カラーミーレスポンシブ
// @import "cms/_commerceCreator"; //コマクリ
// @import "cms/_bcart"; //Bカート
// @import "cms/_ecForce"; //ECフォース

// @import "cms/_makeshopPC"; //Makeshop ベーシックモードPC
// @import "cms/_makeshopSP"; //Makeshop ベーシックモードSP
// @import "cms/_colormePC"; //カラーミーPC
// @import "cms/_colormeSP"; //カラーミーSP

// 標準モジュール読み込み
@import "module/header/_drawer.scss";
@import "module/header/_hdr.scss";
@import "module/header/_hdrA.scss";
@import "module/header/_hdrB.scss";
@import "module/header/_hdrD.scss";
@import "module/header/_hdrE.scss";
@import "module/header/_hdrF.scss";
@import "module/header/_hdrG.scss";
@import "module/header/_hdrH.scss";
@import "module/header/_hdrSearch.scss";
@import "module/footer/_ftr.scss";
@import "module/itemList/_itemList.scss";
@import "module/articleList/_articleList.scss";
@import "module/articleList/_newsList.scss";
@import "module/topMain/_integratedHeader.scss";
@import "module/topMain/_mainSlider.scss";
@import "module/topMain/_mainVisual.scss";
@import "module/bnr/_bnr.scss";
@import "module/bnr/_cateList.scss";
@import "module/bnr/_textBnr.scss";
@import "module/contents/_concept.scss";
@import "module/contents/_itemArrange.scss";
@import "module/contents/_keywordSearch.scss";
@import "module/contents/_shopInfo.scss";
@import "module/contents/_shoppingGuide.scss";
@import "module/contents/_tab.scss";
@import "module/contents/visibility.scss";
@import "module/gallery/_gallery.scss";
@import "module/float/_detailSearch.scss";
@import "module/float/_toTop.scss";
@import "module/search/_detailSearch.scss";
@import "animation/_wow.scss";
@import "add/cateBlock.scss";
@import "add/category.scss";
@import "add/faq.scss";
@import "add/specChart.scss";
@import "add/specTable.scss";
@import "add/voice.scss";
@import "_slick";
@import "_slick-theme";

// パーツモジュール
@import "parts/button/_button.scss";
@import "parts/footer/_copyright.scss";
@import "parts/footer/_fNav.scss";
@import "parts/general/_arrow.scss";
@import "parts/general/_iframe.scss";
@import "parts/general/_searchForm.scss";
@import "parts/general/_table_base.scss";
@import "parts/general/headline/h1.scss";
@import "parts/general/headline/h2.scss";
@import "parts/general/headline/h3.scss";
@import "parts/header/_btnNav.scss";
@import "parts/header/_categoryNavC.scss";
@import "parts/header/_categoryNavD.scss";
@import "parts/header/_exNav.scss";
@import "parts/header/_fixedNav.scss";
@import "parts/header/_gNav.scss";
@import "parts/header/_gNavA.scss";
@import "parts/header/_gNavB.scss";
@import "parts/header/_gNavC.scss";
@import "parts/header/_gNavD.scss";
@import "parts/header/_hdrInfo.scss";
@import "parts/header/_iconNav.scss";
@import "parts/header/_megaMenu.scss";

@import "_custom";



