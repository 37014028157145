[class*="visibility"]{
  content-visibility: auto;
}
.visibility200{
  contain-intrinsic-size: 200px;
}
.visibility300{
  contain-intrinsic-size: 300px;
}
.visibility500{
  contain-intrinsic-size: 500px;
}
.visibility1000{
  contain-intrinsic-size: 1000px;
}
.visibility1500{
  contain-intrinsic-size: 1500px;
}
.visibility2000{
  contain-intrinsic-size: 2000px;
}
