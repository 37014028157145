.textBnr__box{
  display: block;
  position: relative;
  transition: 0.15s;
  > span{
    position: absolute;
    inset: auto 0 0 0;
    padding: 45px;
  }
  &:hover{
    opacity: 0.8;
    transition: 0.15s;
  }

  h3{
    font-size: 24px;
  }
  h3,p{
    line-height: 1.5;
  }
  h3 + p{
    margin-top: 12px;
  }
  .tb__btn{
    margin-top: 24px;
  }
  @media (min-width: 768px) {
    &.tb--narrow{
      > span{
        padding: 30px;
      }
      h3{
        font-size: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    > span{
      padding: 30px;
    }
    h3{
      font-size: 16px;
    }
    h3 + p{
      margin: 10px 0 0 0;
    }
    .tb__btn{
      margin-top: 20px;
    }

    .button{
      font-size: 12px;
      width: 170px;
    }
  }
}
