$hdrMargin: 24;

@mixin hdrH--PC() {
  .hdr--typeI ,
  .hdr--typeH {
    background: $color_body_bg;
    &#hdr .searchForm{
      width: 250px;
    }


    .hdrMiddle{
      padding-top: $hdrMargin+px;
      box-shadow: none;
    }
    .hdrMiddle .hdrInner{
      height: auto;
    }
    .hdrBottom{
      background: $color_body_bg;
    }
    .gNav:not(.gNav--fix) > ul > li > a{
      height: auto !important;
      min-height: auto !important;
    }
    .gNav.gNav--fix > ul > li > a{
      height: 120px;
    }
    .hdrBottom .gNav > ul > li > a{
      height: auto;
      padding-top: $hdrMargin+px;
      padding-bottom: $hdrMargin+px;
    }
  }
}
@if $device_mode == 'pc'{
  @include hdrH--PC();
}

@if $device_mode == 'responsive'{
  @media (min-width: $braekpoint_tab+px) {
    @include hdrH--PC();
  }
}
