.highlight{
  background: $color_highlight_bg;
  color: $color_highlight_text;
  padding: $pc_section_margin_bottom+px 0;
  @media (max-width: 767px) {
    padding: $sp_section_margin_bottom+px 0;
  }
}

.reverse{
  &.row{
    flex-direction : row-reverse
  }
}

// テキスト関連
.text-center,.text--center{
  text-align: center !important;
}
.text--right{
  text-align: right !important;
}
.text--left{
  text-align: left !important;
}

h2.text--right span,
h2.text--right small{
  text-align: right !important;
}
h2.text--left span,
h2.text--left small{
  text-align: left !important;
}
@media screen and (min-width: 768px){
  .pc--text--center{
    text-align: center;
  }
  .pc--text--right{
    text-align: right;
  }
  .pc--text--left{
    text-align: left !important;
  }
}

@media screen and (max-width: 767px){
  .sp--text--center{
    text-align: center !important;
  }
  .sp--text--right{
    text-align: right !important;
  }
  .sp--text--left{
    text-align: left !important;
  }
}


.text--white{
  color:$color_white !important;
}
.text--xxxlarge{
  font-size: 32px;
}
.text--xxlarge{
  font-size: 24px;
}
.text--xlarge{
  font-size: 18px;
}
.text--large{
  font-size: 16px;
}
.text--small{
  font-size: 12px;
}
.text--xsmall{
  font-size: 10px;
}
@if $device_mode != 'pc'{
  @media screen and (max-width: 767px){
    .text--xxxlarge{
      font-size: 24px;
    }
    .text--xxlarge{
      font-size: 20px;
    }
  }
}

.text--primary{
  color:$color_primary !important;
}
.text--secondary{
  color:$color_secondary !important;
}

.text--verticalPC{
  @if $device_mode == 'pc'{
    text-orientation: upright;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    h2,h3,p{
      margin-bottom: 0;
      margin-left: 30px;
      line-height: 2;
      letter-spacing: 0.15em;
      &:last-child{
        margin-left: 0;
      }
    }
    h2{
      letter-spacing: 025em;
    }
  }@else{
    @media screen and (min-width: $braekpoint_tab+px){
      text-orientation: upright;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      h2,h3,p{
        margin-bottom: 0;
        margin-left: 30px;
        line-height: 2;
        letter-spacing: 0.15em;
        &:last-child{
          margin-left: 0;
        }
      }
      h2{
        letter-spacing: 0.2em;
      }
    }
  }
}

.bg--primary{
  background: $color_primary !important;
  color:$color_primary_text !important;
  h2,h3,p{
    color:$color_primary_text;
  }
}
.bg--secondary{
  background: $color_secondary !important;
  color:$color_secondary_text !important;
  h2,h3,p{
    color:$color_secondary_text;
  }
}
.bg--highlight{
  background: $color_highlight_bg !important;
}
.bg--white{
  background: #fff !important;
}
.text--black{
  color: $color_black !important;
}

.mincho,
.text--serif{
  font-family: 'Noto Serif JP', "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif !important;
}

.flex-center,
.tab--typeB .tabSwitch,
.tab--typeC .tabSwitch,
.iconNav__cart .cartAmount
{
  display: flex;
  align-items: center;
  justify-content: center;
  .flex-center__inner{
    display: block;
    width: 100%;
  }
}

.alignCenter,
.newsList li,
[class*="iconButton"],
a[class*="iconButton"],
.newsList--typeB,
.tab--typeA .tabSwitch li,
.hdr--typeE .hdrMiddle__middle,
.hdr--typeE .hdrBottom .hdrInner,
.telAndTime > div
{
  display: -ms-flex;
  display: flex;
  align-items:center;
}
.alignCenter__wrap{
  max-width: 100%;
flex: 0 0 100%;
}

// マージン関連
.mb0{
  margin-bottom: 0 !important;
}
.mb15{
  margin-bottom: 15px !important;
}
.mb30{
  margin-bottom: 30px !important;
}
.mb60{
  margin-bottom: 60px !important;
}
.mb80{
  margin-bottom: 80px !important;
}
.mb100{
  margin-bottom: 100px !important;
}

.mt0{
  margin-top: 0 !important;
}
.mt15{
  margin-top: 15px !important;
}
.mt30{
  margin-top: 30px !important;
}

.dnone{
    display: none;
}

.p0{
  padding: 0;
}

.iframeWrap{
  padding-top: 56.25%;
  position: relative;
  iframe{
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}

video{
  max-width: 100%;
}

.wp__memberOnly,.wp__guestOnly{
  display: none !important;
}


