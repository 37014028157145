.hdr--typeD {
  .hdrTop{
    background: $color_primary;
    color: $color_primary_text;
    .text--primary{
      color: $color_primary_text !important;
    }
  }
  .hdrMiddle{
    .gNav > ul > li > a{
      display: -ms-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      >*{
        max-width: 100%;
        flex: 0 0 100%;
      }
      @media all and (-ms-high-contrast:none){
        line-height: 1.4;
        >*{
          -ms-flex-preferred-size: auto;
          flex-basis: auto;
        }
      }
    }
  }
  .hdrBottom{
    background: $color_highlight_bg;
    li {
      list-style-type: none;
    }
    .hdrSearch--togglewrap {
      position: relative;
    }
    .hdrSearch--toggle {
      position: static;
      display: flex;
      align-items: center;
      margin-left: 0px;
      span {
        display: block;
        text-align: center;
        background: $color_primary;
        color: $color_primary_text;
        width: 100%;
        padding: 10px;
      }
    }
  }
}
