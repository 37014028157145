.gNav{
  > ul{
    display: -ms-flex;
    display: flex;
    > li{
      display: block;
      &:not(:last-child){
        margin-right: 30px;
      }
      > a{
        text-decoration: none;

        strong{
          display: block;
          margin-bottom: 8px;
          font-weight: $base_font_weight;
        }
        &:hover{
          color: $color_primary;
          text-decoration: none;
        }
      }
    }
  }

  li.hasChildren{
    > a{
      padding-right: 16px;
      &::after{
        content: "";
        right: 0;
      }
    }
  }
}
.hdrMiddle {
  .gNav > ul > li > a,
  .gNav > ul > li > span{
    height: 80px;
    @if $device_mode != 'pc'{
      @media (max-width: $container - 1+px) {
        height: 60px;
      }
    }
  }
}
.gNav__listIcon{
  display: inline-block;
  margin-right: 5px;
}

nav.hrd_keywordWrap {
    display: flex;
    align-items: center;
}

.gNav > ul > li > a,
.hrd_keyword a {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 60px;
  @media all and (-ms-high-contrast: none) {
    line-height: 60px;
  }
}

.hrd_keyword{
  display: flex;
  > li{
    margin-right: 15px;
  }
  a {
    margin-right: 20px !important;
    font-weight: $base_font_weight;
    color: $color_primary;
  }
}


.hrd_keywordWrap .hrd_keywordTitle {
  align-items: center;
  display: flex !important;
  margin-right: 30px !important;
}

.hrd_keywordTitle i {
  margin-left: 10px;
}
