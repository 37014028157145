.wow{
  opacity: 0;
  transform: translateY(50px);
}

.wow.animated{
  opacity: 1;
  transition: 1.5s;
  transform: translateY(0px);
}
