.faqWrap{
  dl{
    display: block;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      padding: 10px;
      margin-bottom: 10px;
    }
    dt{
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      padding-right: 30px;
      @media (max-width: 767px) {
        font-size: 12px;
      }
      cursor: pointer;
      &:before{
        content: "Q.";
        color: #459dc7;
      }
      &:after{
        content: "";
        display: block;
        right: 0;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 5px;
        border-top: 3px solid #aaa;
        border-left: 3px solid #aaa;
        transform: rotate(-135deg);
        transition: 0.15s;
        @media (max-width: 767px) {
          width: 12px;
          height: 12px;
          top: 2px;
        }
      }
      &.open{
        transition: 0.15s;
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 16px;
        padding-bottom: 16px;
        &:after{
          transform: rotate(45deg);
          @media (max-width: 767px) {
            top: 5px;
          }
        }
      }
    }
    dd{
      display: none;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 767px) {
        font-size: 12px;
      }
      &:before{
        content: "A.";
        top: 0px;
        color: #e05a73;
      }
    }

    dt,dd{
      padding-left: 36px;
      position: relative;
      text-align: left;
      &:before{
        position: absolute;
        left: 0;
        font-size: 24px;
        line-height: 1;
        font-weight: bold;
      }
      @media (max-width: 767px) {
        padding-left: 30px;
        line-height: 20px;
        &:before{
          font-size: 20px;
        }
      }
    }
  }

  &.faqWrap--noJS{
     dl dd{
       display: block;
     }
     dl dt{
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 16px;
      padding-bottom: 16px;
      cursor: auto;
     }
      dl dt:after{
        content: none;
      }
  }
}
