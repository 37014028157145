.hdr--typeE {
  .hdrTop{
    background: $color_primary;
    color: $color_primary_text;
    p{
      color: $color_primary_text;
    }
    a{
      color: $color_primary_text;
      &::after{
        border-color: $color_primary_text !important;
      }
      &:hover{
        color: $color_primary_text;
      }
    }
  }

  .hdrMiddle{

  }

  .hdrMiddle__left{
    max-width: none;
    flex: 0 0 auto;
  }

  .hdrMiddle__middle{
    max-width: none;
    flex: 2 0 auto;
    padding: 0 40px;
    justify-content: space-between;
  }

  .hdrMiddle__right{
    max-width: none;
    flex: 0 0 auto;
  }

  .hdrBottom{
    background: $color_highlight_bg;
    .hdrInner{
      justify-content: space-between;
      .gNav{
        ul{
          justify-content: flex-start;
        }
      }
      .searchForm{
        width: auto;
        max-width: 300px;
        flex: 0 0 300px;
      }
    }
  }
}



.hdrSearch_wrap {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  .searchForm__inner{
    border-radius: 0 !important;
  }
  .searchForm {
    width: auto;
    max-width: 200px !important;
    flex: 0 0 200px !important;
  }

  .button {
    width: 185px;
    flex: 0 0 185px;
    padding: 5px 30px;
  }

  @media (max-width: $braekpoint_tab - 1 + px) {
    width: 100%;
    max-width: 500px;

    .searchForm {
      margin-right: 0;
      margin-left: 0;
      max-width: calc(100% - 165px) !important;
      flex: 0 0 calc(100% - 165px) !important;
    }

    .button {
      width: 155px;
      flex: 0 0 155px;
      padding: 5px 10px;
    }
  }
}