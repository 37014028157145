.pageH1{
  margin-top: 100px;
  @media (max-width: $braekpoint_sp1 - 1+px) {
    margin-top: 60px;
  }
}

.h1A{
  font-size: 32px;
  text-align: center;
  margin-bottom: 60px;
  @media (max-width: $braekpoint_sp1 - 1+px) {
    font-size: 24px;
    margin-bottom: 30px;
  }
}
