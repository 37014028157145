.articleList{
  time{
    font-size: 12px;
    display: block;
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
}
.articleList__img{
  margin: auto auto 15px auto;
  display: block;
  max-width: 100% !important;
}

.articleList__title{
  font-size: 14px;
  margin: 0 0 10px 0;
  line-height: 24px;
  font-weight: $strong_font_weight;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: $braekpoint_sp1+px) {
    font-size: 12px;
    line-height: 20px;
  }
}
.articleList__content{
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1;
}

.articleList__wrap{
  transition: 0.15s;
  display: block;
  &:hover,&:active{
    color: $color_primary;
    transition: 0.15s;
    opacity: 0.65;
  }
}
.articleList__content{
  @media (max-width: $braekpoint_sp1+px) {
    display: none;
  }

}


.newsList__date{
  font-size: 12px;
  display: block;
  margin-bottom: 10px;
}

.articleList--typeB{
  .articleList__wrap{
    transition: 0.1s;
    display: block;
    box-shadow:0px 2px 6px 0px rgba(0,0,0,0.25);
    &:hover,&:active{
      transition: 0.2s;
      box-shadow:0px 4px 10px 0px rgba(0,0,0,0.3);
      transform: translateY(-3px);

      .articleList__text{
        &:before{color: $color_primary;}
        &:after{border-color: $color_primary;}
      }
    }
  }
  .articleList__img{
    margin-bottom: 0;
  }
  .articleList__text{
    background: #fff;
    display: block;
    padding: 20px;
    @media (max-width: $braekpoint_sp1+px) {
      padding: 15px;
    }
    position: relative;
    &:before{
      content: "MORE";
      position: absolute;
      right: 18px;
      top: -22px;
      font-size: 12px;
      font-weight: $strong_font_weight;
    }
    &:after{
      right: 10px;
      top: -16px !important;
    }
  }
}

.articleList--typeC{
  .articleList__wrap{
    display: block;
    .articleList__text{
      padding: 20px;
      transition: 0.1s;
      display: block;
      box-shadow:0px 2px 6px 0px rgba(0,0,0,0.25);
      transform: translateY(-15px);
      z-index: 2;
      background: #fff;
      margin: 0 10px;
    }
    &:hover,&:active{
      .articleList__text{
        transition: 0.2s;
        box-shadow:0px 4px 10px 0px rgba(0,0,0,0.3);
        transform: translateY(-17px);
      }
    }
  }
  .articleList__img{
    margin-bottom: 0;
  }
}


.articleList--typeD{
  .articleList__wrap{
    background: $color_body_bg;
    display: flex;
    position: relative;
    align-items: center;
    @media all and (-ms-high-contrast:none){
      align-items: center;
    }
    &:before{
      content: "MORE";
      position: absolute;
      right: 28px;
      bottom: 20px;
      font-size: 12px;
      font-weight: $strong_font_weight;
    }
    &:after{
      content: "";
      right: 20px;
      bottom: 20px !important;
      top: inherit !important;
    }

    &:hover,&:active{
      transition: 0.2s;
      box-shadow:0px 2px 4px 0px rgba(0,0,0,0.25);
      transform: translateY(-3px);
      padding-right: 0 !important;
      &:before{color: $color_primary;}
      &:after{border-color: $color_primary;}
    }
  }
  .articleList__img{
    margin-bottom: auto;
    min-height: 135px;
    max-width: 135px !important;
    @media all and (-ms-high-contrast:none){
      min-height: inherit;
      height: auto;
    }
    flex: 0 0 135px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .articleList__title{
    -webkit-line-clamp: 1;
  }
  .articleList__text{
    padding: 20px;
    max-width: calc(100% - 135px);
    flex: 0 0 calc(100% - 135px);
  }
  @media (max-width: $braekpoint_tab+px) {
    .articleList__wrap{
      align-items: center;
    }
    .articleList__img{
      max-width: 120px !important;
      flex: 0 0 120px;

      -o-object-fit: cover;
         object-fit: cover;
         min-height: 120px;
         height: 120px;
    }
    .articleList__title{
      margin-bottom: 15px;
      -webkit-line-clamp: 2;
    }
    .articleList__text{
      padding: 20px;
      max-width: calc(100% - 120px);
      flex: 0 0 calc(100% - 120px);
    }
  }
  &.radius{
    .articleList__wrap{
      border-radius: 10px;
      border: 1px solid $color_border;
      overflow: hidden;

    }
  }
}
