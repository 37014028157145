@if $device_mode != 'pc'{
  .hdrCatMenu{
    height: 40px;
    display: flex;
    align-items: center;
    &.hdrCatMenu2row{
      flex-wrap: wrap;
      .hdrCatMenu__col{
        flex-basis: 50%;
        max-width: 50%;
      }
    }
    &.hdrCatMenu3row{
      .hdrCatMenu__col{
        flex-basis: 33.33%;
        max-width: 33.33%;
      }
    }
    &.hdrCatMenu--simple {
     .hdrCatMenu__col {
      &:nth-child(2) {
       border-left: 1px solid $color_border;
       border-right: 1px solid $color_border;
      }
      .hdrCatMenu--toggle {
       &::after, &::before {
        content: none;
       }
       background: $color_primary;
       color: $color_primary_text;
       font-size: 12px;
       &.open {
        background: $color_body_bg;
        color: $color_text;
       }

      }
     }
    }
    &.hdrCatMenuD2 {
      border-top: solid 1px $color_border;
      border-bottom: solid 1px $color_border;
      .hdrCatMenu__col {
        &:nth-of-type(n+2) {
          border-left: solid 1px $color_border;
        }
        > ul {
          border-top: solid 1px $color_border;
        }
      }
      .hdrCatMenu--toggle {
        background: #fff;
        &.open {
          background: $color_primary;
          color: $color_primary_text;
        }
        &:before, &:after {
          content: none;
        }
      }
    }
  }
  .hdrCatMenu--toggle{
    display: block;
    position: relative;
    &:after,&:before{
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 2px;
      background: $color_text;
      top: 50%;
      right: 0px;
    }
    &:before{
      transform: rotate(90deg);
      transition: 0.15s;
    }
    &.open{
      &:before{
        transform: rotate(0deg);
        transition: 0.15s;
      }
    }
  }
  .hdrCatMenu__col{
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    i{
      line-height: 40px;
      vertical-align: middle;
    }
    ul{
      display: none;
      z-index: 2;
      position: absolute;
      // top: 100%;
      // left: 0;
      // right: 0;
      inset: 100% 0 auto 0;
      &.hdrCatMenu_category {
        > li {
          &.hasChildren--click {
            > a {
              &:hover:after {
                right: 15px;
              }
              &:after {
                transform: rotate(135deg) translateY(-50%);
              }
              &.open:after {
                transform: rotate(-45deg) translateY(-50%);
              }
            }
          }
          > a {
            line-height: 50px;
            &:after {
              width: 8px;
              height: 8px;
            }
          }
          a {
            background: #999;
            color: #fff;
            &:after {
              border-color: #fff;
            }
            &.open {
              border-bottom: none;
            }
          }
          ul {
            position: static;
            background: #999;
            padding: 11px 33px 22px;
            border-bottom: solid 1px $color_border;
            li {
              a {
                font-size: 12px;
                line-height: 24px;
                border-bottom: none;
              }
            }
          }
        }
      }
      &.row {
        background: #ECECEC;
        padding: 25px 15px 15px;
        &.open {
          display: flex!important;
        }
        a {
          background: none;
          padding: 0;
          text-align: inherit;
          &:after {
            content: none;
          }
          small {
            display: block;
            line-height: 1;
            margin-top: 5px;
          }
        }
      }
      a{
        display: block;
        text-align: left;
        padding-left: $sp_bezel+px;
        background: #fff;
        border-bottom: 1px solid $color_border;
      }
    }
  }
}
