.snsNav{
  list-style: none;
  display: -ms-flex;
  display: flex;
  align-items: center;
  li{
    list-style: none;
    margin-right: 20px;
    @if $device_mode != 'pc'{
      @media (max-width: $braekpoint_tab - 1+px){
        margin: 0 10px;
      }
    }
  }
  i{
    font-size: 24px;
  }
  &.snsNav--small{
    i{
      font-size: 18px;
    }
    li{
      margin-right: 15px;
    }
  }
}

.ftr--typeA{
  background: $color_body_bg;
  padding: 0;
}
.ftr--typeC{
  background: $color_body_bg;
  @media (min-width: $braekpoint_tab+px){
    background: $color_primary;
  }
  padding: 0;

}
