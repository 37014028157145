.hdr--typeA {
  .iconNav__cart .cartAmount{
    background: $color_secondary;
  }
  .hdrBottom{
    background:#fff;
    color: $color_primary;
    border-top: 1px solid $color_primary;
    a{
      &:hover{
        color: $color_primary;
      }
    }
    .hasChildren > a::after{
      border-color:$color_text;
    }
    .hasChildren > a:hover::after{
      border-color: $color_primary;
    }
  }
}
