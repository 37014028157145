#toTop,
.toDetailSearch{
  display: block;
  position: fixed;
  z-index: 995;
  bottom: 30px;
  right: 30px;
  a{
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: $color_highlight_bg;
    padding-top: 5px;
    &:hover{
      opacity: 1;
    }

  }
  &.toTop--circle{
    a{
      border-radius: 100px;
    }
  }
  &.toTop--primary{
    a{
      background: $color_primary;
      color: $color_primary_text;
      &:after{
        border-color: $color_primary_text;
      }
    }
  }
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_tab+px) {
      bottom: 10px;
      right: 10px;
    }
  }
}

#toTop{
  a:after{
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border-top: 1px solid $color_text;
    border-right: 1px solid $color_text;
    transform: rotate(-45deg);
    transform-origin: center;
  }
}
.toDetailSearch{
  bottom: 90px;
  // right: auto;
  // left: 30px;
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_tab+px) {
      // left: 10px;
      bottom: 70px;
    }
  }
  a{
    padding: 0 0 5px;
    width: 55px;
    height: 55px;
    >span{
      display: block;
    }
  }
  i{
    display: block;
    text-align: center;
  }
  small{
    display: block;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
  }

}
