.hdrInner{
  .searchForm{
    width: 260px;
    @if $device_mode != 'pc'{
      @media (max-width: $container - 1 +px) {
        width: auto;
      }
    }
  }
}
#hdrLogo{
  font-size: 20px;
  margin-bottom: 0;
  img{
    max-width: 100%;
    height: auto;
  }
}

.hdrTop.hdr--primaryBg,
.hdrBottom.hdr--primaryBg{
  background: $color_primary;
  color: $color_primary_text;
  a{
    color: $color_primary_text;
    &:after{
      border-color: $color_primary_text !important;
    }
    &:hover{
      color: $color_primary_text;
    }
  }
  .text--primary{
    color: $color_primary_text !important;
  }
}
.hdrTop.hdr--highlightBg,
.hdrBottom.hdr--highlightBg{
  background: $color_highlight_bg;
  color: $color_highlight_text;
  a{
    color: $color_highlight_text;
    &:after{
      border-color: $color_highlight_text !important;
    }
    &:hover{
      color: $color_highlight_text;
    }
  }
}
.hdrTop.hdr--secondaryBg,
.hdrBottom.hdr--secondaryBg{
  background: $color_secondary;
  color: $color_secondary_text;
  a{
    color: $color_secondary_text;
    &:after{
      border-color: $color_secondary_text !important;
    }
    &:hover{
      color: $color_secondary_text;
    }
  }
}
.hdrInner{
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @if $device_mode == 'pc'{
    @media (max-width: $container - 40 +px) {
      padding: 0 20px;
    }
  }


}
.text--center .hdrInfo{
  text-align: center;
}

@media (min-width: $container+px) {
  .hdrInner {
    max-width: $container+px;
  }
}

// ヘッダー上部表示（上部メッセージなど）
.hdrTop{
  background: #fff;
  text-align: center;
  .hdrInner:not(.hdrInner--one){
    @if $device_mode == 'pc'{
      >*{
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
    @if $device_mode == 'responsive'{
      @media (min-width: $container+px) {
        >*{
          max-width: 50%;
          flex: 0 0 50%;
        }

      }

      @media (max-width: $container - 1+px) and (min-width: $braekpoint_tab+px) {
        .hdrInfo{
          max-width: calc(100% - 600px);
          flex: 0 0 calc(100% - 600px);
        }
        .exNav{
          max-width: 600px;
          flex: 0 0 600px;
        }
      }
    }
    @if $device_mode != 'pc'{
      @media (max-width: $container - 1+px) {
        padding:0 $sp_bezel+px;
      }
    }
    @if $device_mode != 'sp'{
      .hdrInfo{
        text-align: left;
        @media (max-width: $braekpoint_tab+px - 1+px) {
          text-align: center;
        }
      }
    }
  }
  .snsNav{
    justify-content: flex-end;
    height: 40px;
    li:last-child{
      margin-right: 0;
    }
  }
}
// ヘッダーメイン部分（ロゴなど）
.hdrMiddle{
  position: relative;
  z-index: 999;
  background: $color_body_bg;
  @if $device_mode != 'pc'{
    // box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  }
  .hdrInner{
    // padding: 15px 0;
    height: 80px;
    @if $device_mode != 'pc'{
      @media (max-width: $container - 1+px) {
        height: 60px;
        padding: 0 $sp_bezel+px;
      }
    }
  }



  &.hdrMiddle--logoCenter{
    .hdrInner{
      justify-content: space-between;
      >*{
        max-width: 33.333%;
        flex: 0 0 33.333%;
      }
      #hdrLogo,.hdrMiddle__center{
        text-align: center;
      }
    }
  }

  &.hdrMiddle--logoLeft{
    .hdrInner{
      justify-content: space-between;
    }
    .hdrMiddle__center{
      flex-grow: 2;
      padding: 0 40px;
    }
  }
}
.hdrMiddle__right{
  .iconNav ul{
    justify-content: flex-end;
  }
}

// ヘッダー下部
.hdrBottom{
  .hdrInner{
    justify-content: space-between;
    @if $device_mode != 'pc'{
      @media (max-width: $container - 1+px) {
        padding:0 $sp_bezel+px;
      }
    }
  }
}
.hdrBottom--onlyNav{
  .gNav{
    margin: auto;
    max-width: 100%;
    flex: 0 0 100%;
    ul{
      justify-content: center;
    }
  }
  .searchForm{
    @if $device_mode != 'pc'{
      @media (max-width: $braekpoint_tab - 1+px){
        margin: 10px 20px;
      }
    }
    width: 100% !important;
  }
}

@if $device_mode != 'pc'{
  .hdrBottom--seachWrap{
    .hdrInner{
      display: block;
      .searchForm{
        padding: 10px 0;
      }
      & +.hdrInner{
        border-top: 1px solid $color_border;
      }
    }
  }
}

.hdrMiddle__right--2{
  display: flex;
  align-items: center;
  .searchForm{
    width: 220px;
    margin-right: 40px;
  }
}

.hdrSearch--toggle{
  >span{
    cursor: pointer;
  }
}
.hdrSearch__wrap{
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 30px;
  background: #fff;
  box-shadow: 0px 3px 3px rgba(0,0,0,0.25);

  .searchForm{
    width: 100%;
    margin: 0;
    max-width: none;
  }
}

@if $device_mode == 'sp'{
  @media (max-width: $container+px) {
    .hdrSearch__wrap{
      padding: 15px;
    }
    .hdrMiddle .hdrInfo,
    .exNav{
      display: none;
    }
    .hdrTop .hdrInner .hdrInfo{
      text-align: center;
    }
    .hdrMiddle--logoCenter #hdrLogo{
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    }
    #hdrLogo{
      max-width: $logo_width+px;
    }

  }
}

@if $device_mode == 'responsive'{
  @media (max-width: $braekpoint_tab - 1+px) {
    header .searchForm {
      width: 100% !important;
      margin: 10px $sp_bezel+px;
    }
    .hdrMiddle .hdrInfo ,
    .exNav{
      display: none;
    }
    .hdrTop .hdrInner .hdrInfo{
      text-align: center;
    }
    #hdrLogo{
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      max-width: $logo_width+px;
      text-align: center;
    }
  }
}


@media (min-width: 1280px) {
  @if $device_mode != 'sp'{
    .hdrTop--radius{
      max-width: 1280px;
      margin: auto;
      border-radius: 0 0 80px 80px;
    }
  }
}

.hdrMiddle__right--2col{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  >*:not(:first-child){
    margin-left: 40px;
  }
}

.hdrMiddle--leftUnit{
  @media (min-width: $braekpoint_tab+px) {
    .hdrMiddle__left{
    }
    .hdrMiddle__center{
      display: flex;
      flex-grow: 3;
      padding: 0 30px;
      align-items: center;
    }
    #hdrLogo{
      margin-right: 30px;
    }
    .hdrMiddle__right{
      flex-basis: 75px;
      flex-grow: 1;
    }
  }
  .gNav.gNav--typeB > ul > li a{
    display: flex;
  }
  @media (max-width: $braekpoint_tab - 1+px) {
    #hdrLogo{
      top: 50%;
      transform: translateY(-50%);
      &.hdrLogo--spLeft{
        left: 75px;
        right: auto;
      }
    }
  }
}
