.menuToggle.bg--primary {
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hdrMiddle--rightUnit{
  .hdrMiddle__right{
    display: flex;
    >*:not(:first-child){
      margin-left: 30px;
    }
  }
}
@media (max-width: $container - 1+px) {
  .menuToggle.bg--primary {
    height: 60px;
    width: 60px;
    margin-left: -15px;
  }
  .hdrMiddle__right .pcOnly{
    display: none !important;
  }
}