#overlay{
  position: fixed;
  display: block;
  z-index: 9999998;
  inset: 0;
  background: rgba(0,0,0,0.8);
  opacity: 0;
  pointer-events: none;
  text-align: right;
  color: #fff;
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;
  i{
    font-size: 30px;
  }
  &.open{
    opacity: 1;
    pointer-events: all;
    transition: 0.3s;
  }

}
#drawer{
  position: fixed;
  display: block;
  z-index: 9999999;
  inset:0 auto 0 -300px;
  background: $color_body_bg;
  width: 300px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;

  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  &.open{
    opacity: 1;
    transition: 0.3s;
    left: 0;
    pointer-events: all;
  }
  .snsNav{
    margin: 25px 15px;
    justify-content: center;
  }
}


.drawer__search{
  padding: 20px;
  border-bottom: 1px solid $color_border;
}
.drawer__nav{
  list-style: none;
  li{
    list-style: none;
    text-align: left;
    a{
      display: block;
      position: relative;
      line-height: 24px;
      padding: 13px 20px;
      font-weight: 500;
      border-bottom: 1px solid $color_border;
      &::after{
        right: 20px !important;
      }
      &.drawerIcon{
        display: -ms-flex;
        display: flex;
        align-items: center;
        .drawerIcon__img{
          max-width: 40px;
          flex: 0 0 40px;
          img{max-width: 100%;}
        }
        .drawerIcon__text{
          max-width: calc(100% - 40px);
          flex: 0 0 calc(100% - 40px);
          padding-left: 10px;
        }
      }
    }
  }
}
.fNav__list,
.drawer__nav{
  li.hasChildren--click{
    >a{
      font-weight: $strong_font_weight;
      // background: $color_highlight_bg;
      &::before,&::after{
        right: 20px !important;
        border: none;
        width: 2px !important;
        height: 12px !important;
        background: $color_text;
      }
      &.color_bg {
        background: $color_highlight_bg;
      }
      &::after{
        transform: rotate(90deg) translateY(-50%) !important;
        transform-origin: top center !important;
      }
      &::before{
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: rotate(0deg) translateY(-50%);
        transform-origin: top center;
        transition: 0.15s;
      }
      &.open{
        &::before{
          transform: rotate(90deg) translateY(-50%) !important;
        }
      }
    }
    >ul{
      // &:not(.open){display: none;}
    }
  }
}

.drawer__account{
}
.drawer__accountText{
  padding: 15px 20px;
  // border-top: 1px solid $color_border;
  border-bottom: 1px solid $color_border;
  background: $color_highlight_bg;
  h2{
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0;
  }
}
.drawer__accountNav{
  list-style: none;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  li{
    &.drawer__accountNavFull{
      max-width: 100%;
      flex: 0 0 100%;
    }
    list-style: none;
    max-width: 50%;
    flex: 0 0 50%;
    a{
      background: #fff;
      border-bottom: 1px solid $color_border;
      font-size: 12px;
      &:nth-child(odd){
        border-right: 1px solid $color_border;
      }
      font-weight: 500;
      padding: 13px 15px;
      display: -ms-flex;
      display: flex;
      align-items: center;
      img,
      i{
        display: inline-block;
        margin-right: 5px;
      }
      &::after {
       content: none !important;
      }
    }
  }
}
.drawer__close{
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  background: $color_primary;
  color: $color_body_bg;
}


a.drawer__close{
  color: $color_body_bg;
}

body.open{
  overflow: hidden !important;
}

.drawer__itemList{
  padding: 30px 15px 20px;
  .itemList__unit{
    .itemWrap{
      padding: 0;
      border: none;
      &:after{
        content: none;
      }
    }
  }
  .itemPrice small,
  .itemPrice,
  .itemName{
    font-size: 10px !important;
  }
  .itemName{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  h3{
    margin-bottom: 15px;
    font-weight: $head_font_weight;
  }
}
