// 見出し
// H2
h1,
h2,
h3,
h4,
h5,
h6,
.post_title {
  font-weight: $head_font_weight;
}
.h2A,
.h2B,
.h2C,
.h2D,
.h2E,
.h2F,
.h2G {
  font-size: $head_font_size + px;
  line-height: 48px;
  margin-bottom: $pc_h2_margin_bottom + px;
  text-align: center;
  font-weight: $head_font_weight;
  letter-spacing: 3px;
  font-family: $heading_font_family;
  small {
    font-size: 12px;
    display: block;
    text-align: center;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 1px;
  }
  @if $device_mode != "pc" {
    @media (max-width: $braekpoint_sp2 - 1+px) {
      font-size: $head_font_size_sp + px;
      line-height: 36px;
      margin-bottom: $sp_h2_margin_bottom + px;
    }
  }
}
.h2G {
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 24px;
    background: $color_text;
    margin: auto;
  }
  > small,
  span {
    margin-bottom: 10px;
  }
}

.h2B {
  position: relative;
  padding-bottom: 10px;
  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 60px;
    height: 2px;
    background: $color_border;
  }
}

.h2C,
.h2E {
  small,
  a {
    color: $color_primary;
  }
  .arrow {
    &:after {
      border-color: $color_primary;
      border-width: 2px;
    }
  }
}

.h2D {
  span {
    display: block;
    margin-top: 6px;
    letter-spacing: 1px;
    line-height: 24px;
  }
  small {
    letter-spacing: 1px;
    display: inline-block;
    padding: 2px 10px;
    color: $color_secondary_text;
    background: $color_secondary;
  }
}

.h2E {
  small {
    font-weight: bold;
  }
}

.h2F {
  padding-bottom: 10px;
  text-align: left;
  display: -ms-flex;
  display: flex;
  align-items: center;
  small {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
  }
  border-bottom: 3px solid $color_primary;
}

.h2-button {
  position: relative;
}
.h2-button_area {
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

.h2-button-typeA,
.h2-button-typeB,
.h2-button-typeC {
  font-size: 12px;
  color: $color_primary !important;
  line-height: 1.4;
  display: inline-flex;
  align-items: center;
  &:after {
    border-top: 1px solid $color_primary;
    border-right: 1px solid $color_primary;
  }
}
.h2-button-typeA {
  padding-right: 25px;
}

.h2-button-typeB,
.h2-button-typeC {
  padding: 5px 25px;
  text-align: center;
  border-bottom: 1px solid $color_primary;
}
.h2-button-typeC {
  border: 1px solid $color_primary;
}
