.subCategoryBox{
  margin-bottom: 30px;
}

.sc_h3A,.sc_h3B{
  font-size: 20px;
  margin-bottom: 20px;
  padding: 5px 0;
  line-height: 1.8;
  @media (max-width: 767px) {
    font-size: 14px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  >a{
    font-size: 12px;
    @media (max-width: 767px) {
      font-size: 10px;
    }
    &:after{
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-top: 1px solid #1b1b1b;
      border-right: 1px solid #1b1b1b;
      transform: rotate(45deg);
      transform-origin: right;
      margin-left: 5px;
    }
  }
}
.sc_h3A{
  border-bottom: 1px solid #1b1b1b;
}

.sc_h3B{
  >a{
    border-radius: 50px;
    border: 1px solid #1b1b1b;
    padding: 2px 12px;
    transition: 0.15s;
    &:hover{
      background: #1b1b1b;
      color: #fff;
      transition: 0.15s;
      &:after{
        border-color: #fff;
      }
    }
  }
}

.subCategory__description.highlightBg{
  background: $color_highlight_bg;
  padding: 1em;
}

.categoryMain{
  img{
    max-width: 100%;
  }
}
