@mixin galleryPC(){
  ul.gallery--typeA {
    display: grid;
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
  }
  ul.gallery--noGutters {
    grid-row-gap:0;
    grid-column-gap:0;
  }

  ul.gallery--typeA > li {
    list-style: none;
  }

  ul.gallery--typeA1 > li:nth-of-type(2),
  ul.gallery--typeA1 > li:nth-of-type(4),
  ul.gallery--typeA1 > li:nth-of-type(8) {
    grid-row: span 2;
    grid-column: span 2;
  }

  ul.gallery--typeA2 > li:nth-of-type(1),
  ul.gallery--typeA2 > li:nth-of-type(5),
  ul.gallery--typeA2 > li:nth-of-type(7) {
    grid-row: span 2;
    grid-column: span 2;
  }

  @media all and (-ms-high-contrast: none) {
    ul.gallery--typeA {
      display: flex;
      margin-left: -7.5px;
      margin-right: -7.5px;
      flex-wrap: wrap;
    }

    ul.gallery--typeA > li {
      list-style: none;

      margin-bottom: 15px;
      max-width: 33.333%;
      flex: 0 0 33.333%;
      padding-top: 33.333%;
      &:not(.gallery--noGutters){
        img{
          width: calc(100% - 15px);
          height: calc(100% - 15px);
          left: 7.5px;
          right: 7.5px;
        }
      }
    }
  }
}

@mixin gallerySP(){
  ul.gallery--typeA {
    display: flex;
    margin-left: -3.5px;
    margin-right: -3.5px;
    flex-wrap: wrap;
    &:not(.gallery--noGutters){
      img{
        width: calc(100% - 7px);
        height: calc(100% - 7px);
        left: 3.5px;
        right: 3.5px;
      }
    }
  }
  ul.gallery--typeA > li {
    list-style: none;
    margin-bottom: 0px;
    padding-top: 33.333%;
    max-width: 33.333%;
    flex: 0 0 33.333%;
  }
  ul.gallery--noGutters {
    margin-left: 0px;
    margin-right: 0px;
  }
  ul.gallery--noGutters > li {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0;
  }
}


.gallery--typeA>li {
  position: relative;
  padding-top: 100%;
}
.gallery--typeA svg,
.gallery--typeA img{
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@if $device_mode == 'pc'{
  @include galleryPC();
}@else{
  @media (max-width: $braekpoint_tab - 1+px) {
    @include gallerySP();
  }
  @media (min-width: $braekpoint_tab+px) {
    @include galleryPC();
  }
}


.gallery--typeB {
  list-style: none;
  width: 100%;
  @media (max-width: $braekpoint_tab - 1 +px) {
    width: calc(100% + #{$sp_base_grid_margin * 2}px);
    .gbSub {
      max-width: calc(100% + 15px);
      flex-basis: calc(100% + 15px);
    }
  }
  align-items: center;
  a{
    display: block;
    text-align: center;
  }
}
@media (min-width: $braekpoint_tab +px) {
  .gbMain{
    margin-bottom: 0;
  }
  .gbSub a{
    position: relative;
    padding-top: 100%;
    img {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .gbSub.row{
    margin-bottom: -$base_grid_margin * 2px;
  }
}
