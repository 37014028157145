table.specChart {
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid #dfdfdf;
  }
  th,
  td {
    padding: 5px 0;
    text-align: center;
  }
  th {
    width: 30%;
    font-weight: normal;
    font-size: 13px;
  }
  td {
    width: 20%;
  }
  td span {
    font-size: 12px;
    background: #f2f2f2;
    border: 1px solid #dfdfdf;
    color: #ccc;
    text-align: center;
    display: block;
    margin: 5px;
    padding: 5px;
  }
  td.select span {
    background: #d06c6c;
    color: #fff;
  }
}
