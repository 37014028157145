.side__detailSearch{
  padding: 15px;
  margin-bottom: 30px;
  border:1px solid $color_border;
  ul,li{
    list-style: none;
  }
  .sideTitle{
    font-size: 14px;
    padding: 0;
    margin-bottom: 6px;
  }

  select{
    width: 100%;
    padding: 6px;
    border: 1px solid $color_border;
    border-radius: 5px;
    -webkit-appearance: auto;
       -moz-appearance: auto;
            appearance: auto;
  }

  input[type="text"],
  input[type="tel"]{
    border: 1px solid $color_border;
    border-radius: 5px;
    width: 100%;
    padding: 2px 5px;
  }
  input[type="checkbox"]{
    display: none;
  }
  label{
    line-height: 1.6;
    display: block;
    vertical-align: middle;
  }
  .radioLabel ,
  .checkLabel {
    cursor: pointer;
    position: relative;
    width: auto;
    line-height: 24px;
  }
  .radioLabel {
    box-sizing: border-box;
    display: inline-block;
    padding: 0px 0px 0 5px;
  }
  .checkLabel {
    box-sizing: border-box;
    display: block;
    padding: 0px 30px;
  }
  .checkLabel::before {
      background: #fff;
      border: 1px solid $color_border;
      content: '';
      display: block;
      height: 16px;
      left: 5px;
      margin-top: -8px;
      position: absolute;
      top: 50%;
      width: 16px;
  }
  .checkLabel.radio::before {
  border-radius:50%;
  }
  .checkLabel::after {
    content: '';
    border-right: 2px solid $color_primary;
    border-bottom: 2px solid $color_primary;
    box-sizing: border-box;
    display: block;
    height: 9px;
    left: 10px;
    margin-top: -6px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 6px;
  }
  input[type=checkbox]:checked + .checkLabel::after {
    opacity: 1;
  }
  input[type=checkbox]:checked + .checkLabel{
    background: $color_highlight_bg;
  }

}
.sds__box{
  margin-bottom: 15px;
}
.sds__hotword{
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid $color_border;
  .sideTitle{
    padding: 0;
  }
  ul{
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    li{
      margin: 0 5px 5px 0;
      a{
        display: block;
        font-size: 10px;
        background: $color_highlight_bg;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }
}

.searchForm + .sds__hotword{
  border-top: none;
  padding-top: 0;
}

.sds__priceRange{
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input{
    max-width: calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
  }
  span{
    font-size: 12px;
  }
}
.sds__color{
  ul{
    display: flex;
    margin: 5px -5px -10px;
    li{
      list-style: none;
      flex: 0 0 34px;
      max-width: 34px;
      padding: 2px;
      margin: 0 10px 10px 0;
    }
  }
  label{
    border-radius: 50%;
    position: relative;
    height: 34px;
    width: 34px;
    &:before,>div{
      position: absolute;
      display: block;
      border-radius: 50%;
    }
    >div{
      inset: 4px;
      z-index: 3;
    }
    &:before{
      content: "";
      position: absolute;
      inset: 0;
      border: 1px solid $color_border;
      background: #fff;
      z-index: 2;
    }
    cursor: pointer;
    span{
      display: none;
    }
  }
  input[type=checkbox]:checked + label:before{
    border-color: $color_primary !important;
    transition: 0.05s;
  }
}

#sds_submit{
  border-radius: 0;
  max-width: 100%;
  width: 100%;
}

.hdrSearch--ds {
  background: #FFF !important;
}

.hdrSearch--ds .side__detailSearch {
  padding: 0;
  margin-bottom: 15px;
  border: none;
}

.hdrSearch__wrap {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: calc(100svh - 120px);
}
.hdrSearch__inner{
  width: 550px;
  max-width: 100%;
  margin: auto;
  .searchForm{
    width: 100% !important;
  }
}
.main__detailSearch  {

  @if $device_mode == 'pc'{
    #sds_submit{
      display: block;
      max-width: 345px;
      margin: auto;
    }
    .side__detailSearch {
      padding: 30px;
    }
  }@else{
    @media (min-width: $braekpoint_tab+px){
      #sds_submit{
        display: block;
        max-width: 345px;
        margin: auto;
      }
      .side__detailSearch {
        padding: 30px;
      }
    }
  }
}
