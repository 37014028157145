@media (max-width: 991px){
    .hdrTop {
        border-bottom: 1px solid $color_primary;
        .hdrInner:not(.hdrInner--one) .hdrInfo{
            text-align: center;
        }
    }
}
.iconNav a small{
    font-size: 12px;
}
.gNav > ul > li > a{
    font-size: 19px;
}
.hdr--typeA .hdrBottom a:hover{
    font-weight: 700;
}
.hdr--typeA .hdrBottom .gNav a:hover {
    color: #009944;
}
.megaMenu__button{
    border-radius: 0;
}
.searchForm.searchFormA .searchForm__inner, .searchForm.searchFormC .searchForm__inner{
    border-radius: 20px;
}
.hdrBottom.hdrBottom--onlyNav .gNav .megaMenu ul{
    justify-content: flex-start;
    .megaMenu__button:hover{
        background:rgba(0,153, 68, 0.1);
        border-color: $color_primary;
    }
    &.megaMenu__brand{
        a.multiLine{
            line-height: 1.1;
            padding: 8px 15px 8px 15px;
        }
    }
}
.mainSlider .slick-arrow, .contentsSlider .slick-arrow{
    width: 45px;
    height: 45px;
    border:none;
    background:url('../img/slider_arrow.png');
}
.mainSlider .slick-next, .contentsSlider .slick-next{
    transform: translate(0, -50%);
    right: -60px;
}
.mainSlider .slick-prev, .contentsSlider .slick-prev{
    transform: rotate(180deg) translate(0, -50%);
    left: -60px;
}
.h2D small{
    background:$color_primary;
}
.newsList--typeB .newsList__body ul{
    height: auto;
    overflow: hidden;
}
.bnrList.contentsSlider .slick-slide{
    background:#fff;
}
[class*="iconButton"]:hover, a[class*="iconButton"]:hover{
    background:rgba(0,153, 68, 0.1);
    border-color: $color_primary;
}
a.button{
    border-width: 3px;
    width: 180px;
    font-family: 'Lato','sans-serif';
    border-color:#333333;
    color:#333333;
    &.arrow:after{
        border-color:#333333;
    }
}
.itemList--ranking.ranking--typeF {
    .itemWrap:after{
        background-color: $color_secondary;
    }
    .itemList__unit:nth-child(n + 2) .itemWrap:after{
        background-color: #333333;
    }
}
.articleList--typeD.radius .articleList__wrap{
    border:none;
    border-radius: 0;
}
.brand-list{
    text-align: center;
    .col-pc-1_5{
        padding-left:10px;
        padding-right: 10px;
    }
    a{
        display: block;
        &:not( > img){
            text-decoration: underline;
        }
    }
}
@media (min-width: 992px){
    .fNav--typeA .fNav__list h3{
        font-size: 19px;
    }
    .fNav--typeA .snsNav i{
        font-size: 28px;
    }
    .fNav--typeA .fNav__list li{
        line-height: 1.75;
    }
}
@media (max-width: 767px){
    .itemList--ranking li:nth-child(n + 9){
        display: none;
    }
}
.company__h3{
    font-size: 18px;
    margin-bottom: 20px;
}
.drawer__nav{
    .color_bg{
        background:rgba(0,153, 68, 0.1);
        color:$color_primary;
        &::after{
            border-color: $color_primary;
        }
    }
}
.snsNav.snsNav--small i{
    font-size: 25px;
}
.h2D small{
    font-size: 14px;
}
@media (min-width: 768px){
    .itemList__unit p{
        font-size: 17px;
        line-height: 1.5;
    }
    .newsList li h3{
        font-size: 17px;
    }
    .articleList__title{
        font-size: 17px;
        line-height: 1.5;
    }
    .articleList__content{
        font-size: 15px;
        line-height: 1.75;
        margin-bottom: 15px;
    }
    .articleList time{
        font-size: 15px;
    }
    .articleList--typeD .articleList__wrap:before{
        font-size: 15px;
    }
}
