.voiceBox{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: #f2f2f2;
  border-radius: 10px;
  margin: 0;
  height: 100%;
}
.voiceBox__img{
  max-width: 140px;
  flex: 0 0 140px;
  margin-right: 15px;
  text-align: center;
}
.voiceBox__text p{
  margin-bottom: 15px;
  font-size: 12px;
}
.voiceBox__text p:last-child{
  margin-bottom: 0;
}
.voiceBox__user{
  border-top: 1px solid #1b1b1b;
  padding-top: 15px;
  font-size: 12px;
  display: block;
  font-style: normal;
}
@media (max-width: 991px) {
  .voiceBox__img{
    max-width: 100%;
    margin-bottom: 15px;
  }
  .voiceBox{
    display: block;
    padding: 15px;
    background: #f2f2f2;
    border-radius: 10px;
  }
  .voiceBox__user{
    text-align: right;
  }
}
