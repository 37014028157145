.specTableWrap {
  overflow: auto;
  margin-bottom: 40px;
  table {
    border-collapse: collapse;
    border: 1px solid #dfdfdf;
  }
  th {
    background: #f2f2f2;
  }
  th,td {
    border: 1px solid #dfdfdf;
    font-weight: normal;
    font-size: 13px;
    padding: 10px 15px;
    min-width: 100px;
    text-align: center;
  }
}
