.row.cateList{
  gap: 20px 0;
  [class*="col-"]{
    padding: 0 10px;
  }
  @media (max-width: $braekpoint_sp1+px) {
    gap: 20px 0;
    [class*="col-"]{
      padding: 0 5px;
    }
    .button {
      width: 100%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      font-size: 11px;
      padding: 5px 10px;
    }
  }
}

.gNav .megaMenu li a.button,
a.iconButton,
.iconButton,
.cateList .button,
[class*="iconButton"],
a[class*="iconButton"]{
  color: $color_text;
  font-weight: $strong_font_weight;
  text-align: left;
  border-radius: 100px;
  border: 1px solid $color_border;
  background: $color_body_bg;
  line-height: 20px;
  padding: 10px 30px 10px 10px !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 100%;
  &:hover{
    // background: $color_highlight_bg;
  }
  @media (max-width: $braekpoint_sp1+px) {
    font-size: 12px;

  //   padding: 10px 30px 10px 10px !important;
  }
}

[class*="iconButton"],
a[class*="iconButton"]{
  &:hover{
    color: $color_primary;
    background: $color_highlight_bg;
  }
  &.noBorder{
    border: none;
    background: transparent;
  }
  b{
    padding: 0 5px;
  }
  span:first-child{
    border-radius: 50%;
    overflow: hidden;
    max-width: 40px;
    flex: 0 0 40px;
    img,svg{
      max-width: 100%;
      height: 40px;
      -o-object-fit: cover;
         object-fit: cover;
     @media all and (-ms-high-contrast:none){
       height: auto;
     }
    }
  }
  span:nth-child(2){
    padding: 0 10px;
    max-width: calc(100% - 40px);
    flex: 0 0 calc(100% - 40px);
  }
  @media (max-width: $braekpoint_sp1 - 1+px) {
    span:first-child{
      max-width: 30px;
      flex: 0 0 30px;
      img,svg{
        height: 30px;
      }
    }
    span:nth-child(2){
      font-size: 12px;
      line-height: 20px;
      max-width: calc(100% - 30px);
      flex: 0 0 calc(100% - 30px);
    }
  }
}

figure{
  display: block;
  margin: 0;
  figcaption{
    font-size: $base_font_size+px;
    display: block;
    line-height: 1.7;
    margin-top: 10px;
    font-weight: $base_font_weight;
    @if $device_mode != 'pc'{
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
  img,svg{
    max-width: 100%;
    margin: auto;
  }
}

.cateList--typeC{
  figcaption{
    @if $device_mode != 'pc'{
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
  }
}

.childrenImg--radius{
  img,svg{
    max-width: 100%;
    margin: auto;
    border-radius: 50%;
  }
}

.iconButton--square,
a.iconButton--square{
  padding: 0 !important;
  border-radius: 0;
  span:first-child{
    border-radius: 0;
    max-width: 50px;
    flex: 0 0 50px;
    img{
      height: 50px;
    }
  }
  span:nth-child(2){
    max-width: calc(100% - 70px);
    flex: 0 0 calc(100% - 70px);
  }
}

.iconButton--squareNoImg{
  border-radius: 0 !important;
}

.cateList__children{
  list-style: none;
  line-height: 24px;
  margin-top: 15px;
  >li{
    margin-bottom: 5px;
    >a{
      display: block;
      text-align: left;
      &:hover{
        color:$color_primary;
      }
    }
  }
  @media (max-width: 767px) {
    display: none;
    font-size: 12px;
  }
}

.cateLink__parents{
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: $strong_font_weight;
  line-height: 24px;
  padding: 8px 0;
  border-bottom: 1px solid $color_border;
  &:hover{
    color:$color_primary;
  }
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .cateList a.cateList__hasChildren{
    &:before,
    &:after{
      width: 10px;
      height: 2px;
      transform:translateY(-50%);
      border: 0;
      right: 13px;
      background: $color_text;
    }

    &:before{
      content: "";
      display: block;
      position: absolute;
      transition: 0.15s;
      top: 50%;
      transform: rotate(90deg) translateY(-50%);
              transform-origin: top center;
    }
    &.open{
      &:before{
        opacity: 0;
        transform: translateY(-50%);
      }
    }
    &:hover:after{
      right: 13px;
    }
  }
  .cateList >*:not(:last-child) .cateList__children{
    margin-bottom: 20px;
  }
}
