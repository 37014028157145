/* カテゴリー */
.cateBlock {
  list-style: none;
  margin-left: 0 !important;
  margin-right: 0 !important;
  li {
    list-style: none;
    border: 1px solid $color_border;
    margin-bottom: 30px;
    @media (max-width: 767px){
      margin-bottom: 20px;
    }
    h3 {
      font-size: 14px;
      text-align: center;
      line-height: 24px;
      font-weight: 500;
    }
    img {
      max-width: 100%;
    }
  }
}

.cateBlock {
  li {
    padding: 10px;
    img {
      margin-bottom: 10px;
    }
  }
}
.cateBlock--wide {
  li {
    padding: 20px;
    img {
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 767px){
  .cateBlock {
    li {
      padding: 5px;
    }
  }
  .cateBlock--wide {
    li {
      padding: 10px;
    }
  }
}
