.searchForm{
  // width: 300px;
  position: relative;
  width: 100%;
  .searchForm__inner{
    position: relative;
    height: 40px;
    background: $color_body_bg;
    display: flex;
    align-items: center;
  }
  input[type="text"]{
    padding: 10px 45px 10px 14px;
    background: transparent;
    border: none;
    line-height: 1;
    width: 100%;
    display: block;
    font-size: 16px;
    transform: scale(0.8);
    transform-origin: center left;
  }
  .searchForm__submit{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    line-height: 1;
    padding: 0 6px;
  }

  &.searchFormA .searchForm__inner{
    border: 1px solid $color_border;
  }

  &.searchFormA .searchForm__inner,
  &.searchFormC .searchForm__inner{
    border-radius: 6px;
    input[type="text"]{
      border-radius: 6px;
    }
  }


  &.searchFormB .searchForm__inner{
    border: 1px solid $color_border;
    border-radius: 100px;
    input[type="text"]{
      border-radius: 100px;
    }
  }

  &.searchFormC .searchForm__inner{
    background: $color_highlight_bg;
  }

  &.searchFormD .searchForm__inner{
    background: $color_highlight_bg;
    border-radius: 100px;
    input[type="text"]{
      border-radius: 100px;
    }
  }

  &.searchFormE{
    border-bottom: 1px solid $color_border;
  }
   i.material-icons {
    color: $color_text;
  }
}
.searchForm input[type="text"]:focus {
  outline: none; box-shadow:none;
}


.keywordRanking__toggle{
  pointer-events: none;
  opacity: 0;
  &.open{
    pointer-events: all;
    opacity: 1;
  }
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  h3{
    border-bottom: 1px solid $color_border;
    font-size: 16px;
    padding: 12px 15px;
  }

  .keywordRanking__close{
    display: block;
    margin: 15px;
    border: 1px solid #000;
    padding: 10px 15px;
    text-align: center;
  }
}

.keywordRanking{
  list-style: none;
  li{
    a{
      padding: 12px 15px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color_border;
      &:before{
        content: "";
        width: 24px;
        height: 24px;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 50px;
        border: 1px solid #000;
        margin-right: 10px;
        font-size: 12px;
      }
    }
    &:nth-child(1) a:before{
      content: "1";
    }
    &:nth-child(1){
      a:before{
        background: #FFF1BD;
        border-color: #EFDA8E;
      }
    }
    &:nth-child(2){
      a:before{
        background: #EAEAEA;
        border-color: #C8C8C8;
      }
    }
    &:nth-child(3){
      a:before{
        background: #FFDDBD;
        border-color: #DFBD9E;
      }
    }
  }
}


// カテゴリー・検索
.ccSearchWrap{
  background: $color_highlight_bg;
  padding: 30px;
  @media (max-width: $braekpoint_sp1 - 1+px){
    padding: 30px 15px;
  }
}
.ccSearch {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

li.ccSearch__priceWrap {
    display: flex;
}

.ccSearch input, .ccSearch select {
    border-radius: 2px;
    border: 1px solid $color_border;
    padding: 4px;
    width: 100%;
}
ul.ccSearch{
  > li {
    max-width: 33.333%;
    flex: 0 0 33.333%;
    vertical-align: middle;
    line-height: 25px;

    label {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  @media (min-width: $braekpoint_sp1 +px){
    margin-left: -10px;
    margin-right: -10px;
    > li {
      padding: 0 10px;
    }
  }
  @media (max-width: $braekpoint_sp1 - 1+px){
    > li {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 14px;
    }
  }
}
input.ccSearch__price {
  max-width: 110px;
  margin: 0 10px;
  &:first-child {
    margin-left: 0;
  }
}
.ccHeadWrap {
    margin: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ccCount {
    margin-right: 15px;
}
.ccCount strong {
    font-size: 18px;
    margin: 0 2px;
}
select.ccSortSelect {
    padding: 5px;
    border: 1px solid $color_border;
}
